import React, { useState, useEffect, useRef, useCallback } from 'react';
import { marked } from 'marked';
import './ChatWidget.css';
import './translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCopy, faCheck, faFileWord, faQuestion, faChartLine, faShieldAlt, faExchangeAlt, faIndustry, faFilePdf, faMicrophone, faCircle } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faX} />
        </button>
        {children}
      </div>
    </div>
  );
};

const ChatWidget = ({ language, themeColor = '#0082ba', hoverColor = '#0595d3' }) => {
  const [copyIcon, setCopyIcon] = useState(faCopy);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const chatBodyRef = useRef(null);
  const chatWindowRef = useRef(null);
  const currentBotMessage = useRef("");
  const [isRecording, setIsRecording] = useState(false);
  const audioContextRef = useRef(null);
  const mediaStreamSourceRef = useRef(null);
  const processorRef = useRef(null);
  const audioBufferRef = useRef([]);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isWelcomeMessageModalOpen, setIsWelcomeMessageModalOpen] = useState(false);

  const copyLastBotMessageToClipboard = () => {
    const lastBotMessage = messages.slice().reverse().find(message => message.sender === 'bot');
    if (lastBotMessage) {
      const htmlContent = marked(lastBotMessage.text);
      const inlineStyledHtml = htmlContent;
      const blob = new Blob([inlineStyledHtml], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({ 'text/html': blob });
      navigator.clipboard.write([clipboardItem])
        .then(() => {
          setCopyIcon(faCheck);
          setTimeout(() => {
            setCopyIcon(faCopy);
          }, 1000);
        })
        .catch(err => {
          console.error('Could not copy HTML with inline styles to clipboard', err);
        });
    }
  };

  const modifyLinks = (htmlText) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');

    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  };

  const renderMessage = (text) => {
    const rawHtml = marked.parse(text);
    return modifyLinks(rawHtml);
  };

  const fetchWelcomeMessages = useCallback((sessionId) => {
    const eventSource = new EventSource(`/api/welcome_message?session_id=${sessionId}&language=${language}`);
    let welcomeMessage = "";
    let isFirstChunk = true;

    const handleMessage = (event) => {
      const chunk = event.data;
      if (chunk !== 'end of response') {
        const parsedChunk = chunk.replace(/<newline>/g, '\n');
        welcomeMessage += parsedChunk;

        if (isFirstChunk) {
          setIsLoading(false);
          isFirstChunk = false;
        }

        setMessages(prevMessages => {
          if (prevMessages.length > 0 && prevMessages[0].sender === 'bot') {
            const updatedMessages = [...prevMessages];
            updatedMessages[0] = { ...updatedMessages[0], text: welcomeMessage };
            return updatedMessages;
          } else {
            return [{ text: welcomeMessage, sender: 'bot' }, ...prevMessages];
          }
        });
        setWelcomeMessage(welcomeMessage);
      }
    };

    const handleError = (error) => {
      setIsLoading(false);
      eventSource.close();
    };

    eventSource.onmessage = handleMessage;
    eventSource.onerror = handleError;

    return () => {
      eventSource.close();
    };
  }, [language]);

  const generateSessionId = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 10000) + 1;
    return `${timestamp}-${randomNum}`;
  };

  const toggleChat = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => setIsVisible(true), 0);
      document.body.classList.add('no-scroll');
      if (!isInitialized) {
        setIsInitialized(true);
      }
    } else {
      setIsVisible(false);
      setTimeout(() => setIsOpen(false), 500);
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen, isInitialized]);

  const sendMessage = async () => {
    if (inputMessage.trim()) {
      const userMessage = { text: inputMessage, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      const messageToSend = inputMessage;
      setInputMessage('');
      setIsLoading(true);
      currentBotMessage.current = "";

      try {
        const eventSource = new EventSource(`/api/widget_chat?user_input=${encodeURIComponent(messageToSend)}&session_id=${sessionId}&language=${language}`);

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
              setIsLoading(false);
              const parsedChunk = chunk.replace(/<newline>/g, '\n');
              currentBotMessage.current += parsedChunk;
              setMessages(messages => {
                  const lastMessage = messages[messages.length - 1];
                  if (lastMessage && lastMessage.sender === 'bot') {
                      return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
                  } else {
                      return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
                  }
              });
          } else {
              eventSource.close();
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          setMessages(prevMessages => [...prevMessages, { text: 'Failed to get response from server.', sender: 'bot' }]);
          setIsLoading(false);
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.sender === 'bot') {
              return [...messages.slice(0, -1), { text: currentBotMessage.current, sender: 'bot' }];
            } else {
              return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
            }
          });
          setIsLoading(false);
          eventSource.close();
        });

      } catch (error) {
        console.error('Error fetching response:', error);
        setMessages(prevMessages => [...prevMessages, { text: 'Failed to get response from server.', sender: 'bot' }]);
        setIsLoading(false);
      }
    }
  };

  const generateWordDocument = async (messages) => {
    try {
        const response = await fetch('/api/generate_word_document', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            throw new Error('Failed to generate document');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ChatHistory.docx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('Error generating document:', error);
    }
  };

  const handleRecordClick = async () => {
    if (isRecording) {
      // Stop recording
      if (processorRef.current && mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect();
        processorRef.current.disconnect();
      }

      // Stop the media stream to release the microphone
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close(); // Close the AudioContext
      }
      if (mediaStreamSourceRef.current && mediaStreamSourceRef.current.mediaStream) {
        mediaStreamSourceRef.current.mediaStream.getTracks().forEach(track => track.stop());
      }

      setIsRecording(false);
      console.log("stopped!");

      // Process and send the recorded audio data to the backend
      processAudioData(audioBufferRef.current);
    } else {
      // Start recording using Web Audio API
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert('Your browser does not support audio recording.');
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;
        mediaStreamSourceRef.current = audioContext.createMediaStreamSource(stream);
        processorRef.current = audioContext.createScriptProcessor(4096, 1, 1);

        mediaStreamSourceRef.current.connect(processorRef.current);
        processorRef.current.connect(audioContext.destination);

        audioBufferRef.current = [];

        processorRef.current.onaudioprocess = (e) => {
          const inputBuffer = e.inputBuffer.getChannelData(0);
          audioBufferRef.current.push(new Float32Array(inputBuffer));
        };

        setIsRecording(true);
        console.log("started recording");
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert('Could not access your microphone. Please check permissions.');
      }
    }
  };

  const processAudioData = async (buffer) => {
    console.log('Processing audio buffer:', buffer);

    setIsAudioLoading(true); // Start loader

    const wavBlob = encodeWAV(buffer);
    const reader = new FileReader();
    reader.readAsDataURL(wavBlob);

    reader.onloadend = async () => {
      const base64data = reader.result.split(',')[1];

      try {
        const response = await fetch('/api/translate_audio_to_text', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ audio_file: base64data }),
        });

        if (!response.ok) {
          throw new Error('Failed to process audio');
        }

        const data = await response.json();
        if (data && data.user_input) {
          setInputMessage(data.user_input);
        } else {
          console.error('Invalid response from API');
        }
      } catch (error) {
        console.error('Error processing audio:', error);
      } finally {
        setIsAudioLoading(false); // Stop loader
      }
    };
  };

  const encodeWAV = (buffers) => {
    const sampleRate = audioContextRef.current.sampleRate;
    const bufferLength = buffers.length * buffers[0].length;
    const buffer = new ArrayBuffer(44 + bufferLength * 2);
    const view = new DataView(buffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + bufferLength * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true); // Mono channel
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, bufferLength * 2, true);

    let offset = 44;
    buffers.forEach((buffer) => {
      for (let i = 0; i < buffer.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, buffer[i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
      }
    });

    return new Blob([view], { type: 'audio/wav' });
  };

  useEffect(() => {
    let cleanup;
    const loadWelcomeMessages = () => {
      if (isOpen && isInitialized) {
        setIsLoading(true);
        setMessages([{ text: '', sender: 'bot' }]);
        cleanup = fetchWelcomeMessages(sessionId);
      }
    };

    loadWelcomeMessages();

    return () => {
      if (cleanup) cleanup();
    };
  }, [isOpen, isInitialized, language, sessionId, fetchWelcomeMessages]);

  useEffect(() => {
    const newSessionId = generateSessionId();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .chat-widget-icon { background-color: ${themeColor}; }
      .chat-header { background-color: ${themeColor}; }
      .chat-footer button { background-color: ${themeColor}; }
      .widget-user-message { background-color: ${themeColor}; }
      .loading-dots div { background-color: ${themeColor}; }
      .chat-footer button:hover { background-color: ${hoverColor}; }
      .recording-icon {
        color: red;
        animation: blink 1s steps(5, start) infinite;
      }
      @keyframes blink {
        to {
          visibility: hidden;
        }
      }
    `;
    document.head.appendChild(style);

    const openTimer = setTimeout(() => {
      setIsOpen(true);
      setTimeout(() => {
        setIsVisible(true);
        setIsInitialized(true);
      }, 50);
    }, 500);

    return () => {
      document.head.removeChild(style);
      clearTimeout(openTimer);
    };
  }, [themeColor, hoverColor]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  return (
    <div className="chat-widget-container">
      <div className="chat-widget-icon" onClick={toggleChat}>💬</div>
      {isOpen && (
        <>
        <div className="chat-overlay" onClick={toggleChat}></div>
        <div className={`chat-window ${isVisible ? 'show' : ''}`} ref={chatWindowRef}>
            <div className="chat-header">
              <div className="chat-header-title">
                <span className="chat-title">Early Lion AI</span>
                <div className='close-word-container'>
                  <div className="question-icon-frank">
                    <FontAwesomeIcon
                      icon={faChartLine}
                      onClick={() => setIsWelcomeMessageModalOpen(true)}
                      title="Portfolio Performance"
                    />
                  </div>
                  <div className="question-icon">
                    <FontAwesomeIcon
                      icon={faQuestion}
                      onClick={() => setIsModalOpen(true)}
                      title="Hilfe"
                    />
                  </div>
                  <div className="export-word-icon">
                    <FontAwesomeIcon
                      icon={copyIcon}
                      onClick={() => copyLastBotMessageToClipboard()}
                      title="Letzte Antwort kopieren"
                    />
                  </div>
                  <div className="export-word-icon">
                    <FontAwesomeIcon
                      icon={faFileWord}
                      onClick={() => generateWordDocument(messages.slice(1))}
                      title="Chatverlauf in Word exportieren"
                    />
                  </div>
                  <button className="close-btn close-chat-widget-icon" onClick={toggleChat}>
                    <FontAwesomeIcon
                      icon={faX}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="chat-body" ref={chatBodyRef}>
              {messages.map((message, index) => (
                message.sender === 'bot' ? (
                  <div key={index} className={`widget-message widget-${message.sender}-message`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                      {renderMessage(message.text)}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`widget-message widget-${message.sender}-message`}
                  >
                    {message.text}
                  </div>
                )
              ))}
              {isLoading && (
                <div className="loading-dots">
                  <div></div><div></div><div></div>
                </div>
              )}
            </div>
            <div className="chat-footer">
              <div className="powered-by">
                <span className="powered-by-text" onClick={() => window.open('https://www.thescreener.com/', '_blank')}>
                  Powered with data by theScreener
                </span>
              </div>
              <div className="input-send-container">
                <div className="input-with-loader">
                  {isAudioLoading && <div className="audio-loader"></div>}
                  <input
                    type="text"
                    className={`chat-input ${isAudioLoading ? 'disabled' : ''}`}
                    placeholder={isAudioLoading ? "" : "Type your message..."}
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => !isAudioLoading && e.key === 'Enter' && sendMessage()}
                    disabled={isAudioLoading}
                  />
                </div>
                <button
                  className={`send-message ${isLoading ? 'disabled' : ''}`}
                  onClick={sendMessage}
                  disabled={!inputMessage.trim() || isLoading}
                >
                  <i className="widget-send-icon">Send</i>
                </button>
                <button
                  className={`record ${isLoading ? 'disabled' : ''}`}
                  onClick={handleRecordClick}
                  disabled={isLoading}
                >
                  <i className="widget-record-icon">
                    {isRecording ? (
                      <FontAwesomeIcon icon={faCircle} />
                    ) : (
                      <FontAwesomeIcon icon={faMicrophone} />
                    )}
                  </i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Earlylion AI Hilfe</h2>
        <p>Sie können Fragen stellen wie:</p>
        <h3><FontAwesomeIcon icon={faChartLine} className="help-icon" /> Portfolios:</h3>
        <ul>
          <li>Wie ist die Performance von meinem Portfolio?</li>
        </ul>
        <h3><FontAwesomeIcon icon={faShieldAlt} className="help-icon" /> Positionen:</h3>
        <ul>
          <li>Wie beurteilen die Experten das Risiko von ABB?</li>
          <li>Was sind die Top 5 Aktien des Stoxx 600?</li>
          <li>Ich suche nach guten Dividendentiteln, was kannst du mir empfehlen?</li>
        </ul>
        <h3><FontAwesomeIcon icon={faExchangeAlt} className="help-icon" /> Alternativen:</h3>
        <ul>
          <li>Gib mir Alternativen zu Kering.</li>
        </ul>
        <h3><FontAwesomeIcon icon={faIndustry} className="help-icon" /> Sektoren:</h3>
        <ul>
          <li>Gib mir Informationen zum Nahrungsmittel Sektor.</li>
        </ul>
        <h3><FontAwesomeIcon icon={faFilePdf} className="help-icon" /> theScreener Reports:</h3>
        <ul>
          <li>Ich möchte den PDF Report von Roche sehen.</li>
        </ul>
      </Modal>
      <Modal isVisible={isWelcomeMessageModalOpen} onClose={() => setIsWelcomeMessageModalOpen(false)}>
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div className="widget-message-frank custom-max-width widget-bot-message-frank">
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
              {renderMessage(welcomeMessage)}
            </ReactMarkdown>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChatWidget;
