import React, { useState } from 'react';
import { Modal, Box, Button, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import './breachesModal.css';
import './translations';

const BreachesModal = ({ open, handleClose, onExpandChat }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const getFutureDate = (daysToAdd) => {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const futureDate = getFutureDate(10);

  const breachesData = [
    { number: '', date: futureDate, portfolio: '230.222.456', bo: 'Petra Muster', strategy: t('stocks'), restriction: t('russian_bonds') },
    { number: '', date: futureDate, portfolio: '230.222.456', bo: 'Petra Muster', strategy: t('stocks'), restriction: t('us_stocks') },
    { number: '', date: futureDate, portfolio: '230.222.588', bo: 'John Doe', strategy: t('balanced'), restriction: t('asset_allocation') },
    { number: '', date: futureDate, portfolio: '230.222.588', bo: 'John Doe', strategy: t('balanced'), restriction: t('russian_bonds') },
    { number: '', date: futureDate, portfolio: '230.321.205', bo: 'Peter Muster', strategy: t('balanced'), restriction: t('concentration_risk') },
    { number: '', date: futureDate, portfolio: '230.321.522', bo: 'Jane Doe', strategy: t('income'), restriction: t('asset_allocation') },
    { number: '', date: futureDate, portfolio: '230.321.522', bo: 'Jane Doe', strategy: t('income'), restriction: t('concentration_risk') },
    { number: '', date: futureDate, portfolio: '230.442.250', bo: 'Maria Müller', strategy: t('fixed_income'), restriction: t('asset_allocation') },
    { number: '', date: futureDate, portfolio: '230.442.300', bo: 'Max Müller', strategy: t('growth'), restriction: t('asset_allocation') },
  ];

  const todoData = [
    { bo: 'John Doe', date: futureDate, portfolio: '230.222.588', task: t('client_meeting_preparation') },
    { bo: 'Petra Muster', date: futureDate, portfolio: '230.222.456', task: t('client_meeting_preparation') },
    { bo: 'Peter Muster', date: futureDate, portfolio: '230.321.205', task: t('send_information') },
    { bo: 'Peter Muster', date: futureDate, portfolio: '230.321.205', task: t('follow_up_client') },
    { bo: 'Maria Müller', date: futureDate, portfolio: '230.442.250', task: t('call_back') },
    { bo: 'Jane Doe', date: futureDate, portfolio: '230.321.522', task: t('aml') },
    { bo: 'Max Müller', date: futureDate, portfolio: '230.442.300', task: t('aml') },
  ];

  const missingDocumentsData = [
    { date: futureDate, clType: t('client'), bo: 'Jane Doe', type: t('expiration'), documentType: t('client_agreement') },
    { date: futureDate, clType: t('prospect'), bo: 'Jane Muster', type: t('missing'), documentType: t('origin_of_wealth') },
    { date: futureDate, clType: t('client'), bo: 'John Doe', type: t('expiration'), documentType: t('client_agreement') },
    { date: futureDate, clType: t('prospect'), bo: 'John Muster', type: t('missing'), documentType: t('client_agreement') },
    { date: futureDate, clType: t('client'), bo: 'Maria Müller', type: t('expiration'), documentType: t('proof_of_address') },
    { date: futureDate, clType: t('prospect'), bo: 'Max Müller', type: t('missing'), documentType: t('tax_residency_declaration') },
    { date: futureDate, clType: t('client'), bo: 'Max Müller', type: t('expiration'), documentType: t('proof_of_address') },
    { date: futureDate, clType: t('client'), bo: 'Peter Muster', type: t('expiration'), documentType: t('passport') },
    { date: futureDate, clType: t('client'), bo: 'Petra Muster', type: t('expiration'), documentType: t('passport') }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedRow(null);
  };

  const handleRowSelection = (index) => {
    if (selectedRow === index) {
      setSelectedRow(null);
    } else {
      setSelectedRow(index);
    }
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    handleClose();
  }

  const currentData = activeTab === 0 ? breachesData
                    : activeTab === 1 ? todoData
                    : missingDocumentsData;

  const handleSpeakWithEarlyLion = () => {
    if (handleClose) handleCloseModal();

    let prefillText = t('more_info_about');
    if (selectedRow !== null && currentData[selectedRow]) {
      const rowInfo = currentData[selectedRow];
      if (activeTab === 0 && rowInfo.restriction) {
        prefillText = t('more_info_about_breaches_from') + rowInfo.bo;
      } else if (activeTab === 1 && rowInfo.task) {
        prefillText = t('more_info_about_todos_from') + rowInfo.bo;
      } else if (activeTab === 2 && rowInfo.documentType) {
        prefillText = t('more_info_about_missing_documents_from') + rowInfo.bo;
      }
    }

    if (onExpandChat) onExpandChat('chat', prefillText);
  };

  const renderTable = () => {
    if (activeTab === 0) {
      return (
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th></th>
                <th>{t('date')}</th>
                <th>{t('client')}</th>
                <th>{t('strategy')}</th>
                <th>{t('breach_against_restriction')}</th>
              </tr>
            </thead>
            <tbody>
              {breachesData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowSelection(index)}
                  className={selectedRow === index ? 'selected-row' : ''}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <input
                      type="radio"
                      checked={selectedRow === index}
                      onChange={() => handleRowSelection(index)}
                    />
                  </td>
                  <td>{row.date}</td>
                  <td>{row.bo}</td>
                  <td>{row.strategy}</td>
                  <td>{row.restriction}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    if (activeTab === 1) {
      return (
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th></th>
                <th>{t('date')}</th>
                <th>{t('client')}</th>
                <th>{t('portfolio')}</th>
                <th>{t('task')}</th>
              </tr>
            </thead>
            <tbody>
              {todoData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowSelection(index)}
                  className={selectedRow === index ? 'selected-row' : ''}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <input
                      type="radio"
                      checked={selectedRow === index}
                      onChange={() => handleRowSelection(index)}
                    />
                  </td>
                  <td>{row.date}</td>
                  <td>{row.bo}</td>
                  <td>{row.portfolio}</td>
                  <td>{row.task}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    if (activeTab === 2) {
      return (
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th></th>
                <th>{t('date')}</th>
                <th>{t('client')}</th>
                <th>{t('cl_type')}</th>
                <th>{t('type')}</th>
                <th>{t('document_type')}</th>
              </tr>
            </thead>
            <tbody>
              {missingDocumentsData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowSelection(index)}
                  className={selectedRow === index ? 'selected-row' : ''}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <input
                      type="radio"
                      checked={selectedRow === index}
                      onChange={() => handleRowSelection(index)}
                    />
                  </td>
                  <td>{row.date}</td>
                  <td>{row.bo}</td>
                  <td>{row.clType}</td>
                  <td>{row.type}</td>
                  <td>{row.documentType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="breaches-modal-title"
      aria-describedby="breaches-modal-description"
    >
      <Box
        className='modal-parent'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        {/* Close button */}
        <div className='breaches-modal-button-container'>
          <button className="breaches-modal-close-btn" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faX} />
          </button>
        </div>
        <div
          className='tabs-parent'>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered={false}
            variant="scrollable"
          >
            <Tab label={t('breaches')} />
            <Tab label={t('to_do')} />
            <Tab label={t('missing_documents')} />
          </Tabs>
        </div>
        <Box mt={2}>
          {renderTable()}
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSpeakWithEarlyLion}
          >
            {t('speak_with_earlylion')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BreachesModal;