import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import "./translations.css";

const resources = {
    de: {
      translation: {
        "greeting": "Guten Tag,",
        "performance": "Portfolio Performance",
        "regionAllocation": "Regionen Analyse",
        "assetClassAllocation": "Asset Class Verteilung",
        "currencyAllocation": "Währungsanteile",
        'kpi': "KPI's YTD",
        'asset_under_management_mgr': 'Verwaltetes Vermögen',
        'net_new_asset_mgr': 'Netto Neugeld',
        'income_information_mgr': 'Einkommensanalyse',
        'new_clients_mgr': 'Neukunden im Jahr',
        'asset_under_management_adv': 'Verwaltetes Vermögen',
        'net_new_asset_adv': 'Netto Neugeld',
        'income_information_adv': 'Einkommensanalyse',
        'new_clients_adv': 'Neukunden im Jahr',
        'client_contact': 'Kundenkontakt',
        "openAll": "Alle öffnen",
        "closeAll": "Alle schliessen",
        "shortSummary": "Kurz",
        "longSummary": "Lang",
        "loadingSummary": "Deine Zusammenfassung wird geladen...",
        "loadingAudio": "Audio wird geladen",
        "customer_note": "Kunden Notiz",
        'date': 'Datum',
        'client': 'Kunde',
        'type': 'Typ',
        'attendees': 'Teilnehmer',
        'comment': 'Kommentar',
        'save': 'Speichern',
        'chat': 'Assistant Lion',
        'chat_welcome_message': '<h3>Wie kann ich Ihnen heute helfen?</h3><p>Ob Sie einen Überblick über die Leistung Ihrer Portfolios 📊 benötigen, spezifische Details zu einem bestimmten Kundenportfolio 🔍 oder hochwertige Analysteninsights zu einzelnen Anlagepositionen oder Branchen von <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> wünschen – ich stehe Ihnen zur Verfügung. Teilen Sie mir einfach mit, welche Informationen Sie benötigen, und ich werde die erforderlichen Daten präzise und effektiv bereitstellen. 🚀</p>',
        'ask_question': 'Frage eingeben...',
        'reset-chat': 'Chat löschen',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mein Kalender',
        'very-important-prompts': 'VIP',
        'load-response': 'Lade Antwort...',
        'load-calendar': 'Lade Kalendereinträge...',
        'description': 'Beschreibung',
        'no-events': 'Keine Kalendereinträge vorhanden.',
        'prepare-me-for-meeting': 'Bereite mich auf meinen Termin vor.',
        'telephone': 'Telefon',
        'on_site': 'Vor Ort',
        'portfolio_performance': 'Portfolio Performance',
        'speak_with_consultant': 'Mit Berater sprechen',
        'help': 'Hilfe',
        'copy_last_response': 'Letzte Antwort kopieren',
        'export_chat_history_to_word': 'Chatverlauf in Word exportieren',
        'minimize_chat': 'Chat verkleinern',
        'maximize_chat': 'Chat vergrössern',
        'close_chat': 'Chat schliessen',
        'speak_with_my_consultant': 'Ich möchte mit meinem Berater sprechen.',
        'breaches': 'Verstösse',
        'to_do': 'Zu erledigen',
        'missing_documents': 'Fehlende Dokumente',
        'strategy': 'Strategie',
        'breach_against_restriction': 'Verstoss gegen Richtlinie',
        'speak_with_earlylion': 'Mit Earlylion sprechen',
        'number': 'Nummer',
        'task': 'Aufgabe',
        'portfolio': 'Portfolio',
        'cl_type': 'Kundentyp',
        'document_type': 'Dokument',
        'stocks': 'Aktien',
        'balanced': 'Ausgewogen',
        'income': 'Ertrag',
        'fixed_income': 'Festverzinslich',
        'growth': 'Wachstum',
        'russian_bonds': 'Russische Anleihen',
        'us_stocks': 'US-Aktien',
        'asset_allocation': 'Asset-Allokation',
        'concentration_risk': 'Konzentrationsrisiko',
        'client_meeting_preparation': 'Kundenbesprechungsvorbereitung',
        'send_information': 'Informationen senden',
        'follow_up_client': 'Follow-up mit Kunde',
        'call_back': 'Rückruf',
        'asset_allocation_breach': 'Asset-Allokationsverstoß',
        'aml': 'Geldwäsche',
        'missing': 'Fehlend',
        'expiration': 'Ablauf',
        'form_a': 'Formular A',
        'contract': 'Vertrag',
        'origin_of_wealth': 'Herkunft des Vermögens',
        'passport': 'Pass',
        'document_xy': 'Dokument XY',
        'more_info_about': 'Mehr infos über ...',
        'more_info_about_breaches_from': 'Mehr infos über die Verstösse von ',
        'more_info_about_todos_from': 'Mehr infos über "To Do" für ',
        'more_info_about_missing_documents_from': 'Mehr infos über die fehlenden Dokumente von ',
        'prospect': 'Interessent',
        'tax_residency_declaration': 'Steueransässigkeitserklärung',
        'client_agreement': 'Kundenvereinbarung',
        'proof_of_address': 'Adressnachweis',
      }
    },
    fr: {
      translation: {
        "greeting": "Bonjour,",
        "performance": "Performance du Portefeuille",
        "regionAllocation": "Analyse de la Région",
        "assetClassAllocation": "Analyse de la Catégorie d'Actifs",
        "currencyAllocation": "Analyse de la Devise",
        'kpi': "KPI's",
        'asset_under_management_mgr': 'Actifs gérés',
        'net_new_asset_mgr': 'Nouveaux actifs nets',
        'income_information_mgr': 'Analyse des revenus',
        'new_clients_mgr': 'Nouveaux clients',
        'asset_under_management_adv': 'Actifs gérés',
        'net_new_asset_adv': 'Nouveaux actifs nets',
        'income_information_adv': 'Analyse des revenus',
        'new_clients_adv': 'Nouveaux clients',
        'client_contact': 'Contact client',
        "openAll": "Ouvrir tout",
        "closeAll": "Fermer tout",
        "shortSummary": "Court",
        "longSummary": "Long",
        "loadingSummary": "Votre résumé est en cours de chargement...",
        "loadingAudio": "Chargement de l'audio en cours",
        "customer_note": "Note client",
        "date": "Date",
        "client": "Client",
        "type": "Type",
        "attendees": "Participants",
        "comment": "Commentaire",
        "save": "Enregistrer",
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>Comment puis-je vous aider aujourd'hui ?</h3><p>Que vous ayez besoin d'un aperçu des performances de vos portefeuilles 📊, de détails spécifiques sur un portefeuille client particulier 🔍 ou de précieuses analyses d'analystes sur des positions d'investissement individuelles ou des secteurs via <b><a href='https://rm.thescreener.com/home/login' target='_blank' class='hover-link'>theScreener</a></b> – je suis à votre disposition. Dites-moi simplement quelles informations vous nécessitez, et je fournirai les données requises avec précision et efficacité. 🚀</p>",
        'ask_question': 'Entrez une question...',
        'reset-chat': 'Réinitialiser le chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mon Calendrier',
        'very-important-prompts': 'VIP',
        'load-response': 'Chargement de la réponse...',
        'load-calendar': 'Chargement des entrées du calendrier...',
        'description': 'Description',
        'no-events': 'Aucune entrée de calendrier disponible.',
        'prepare-me-for-meeting': 'Prépare-moi pour ma réunion.',
        'telephone': 'Téléphone',
        'on_site': 'Sur place',
        'portfolio_performance': 'Performance du Portefeuille',
        'speak_with_consultant': 'Parler avec un Conseiller',
        'help': 'Aide',
        'copy_last_response': 'Copier la Dernière Réponse',
        'export_chat_history_to_word': "Exporter l'Historique du Chat en Word",
        'minimize_chat': 'Réduire le Chat',
        'maximize_chat': 'Agrandir le Chat',
        'close_chat': 'Fermer le Chat',
        'speak_with_my_consultant': 'Je veux parler avec mon conseiller.',
        'breaches': 'Infractions',
        'to_do': 'À faire',
        'missing_documents': 'Documents manquants',
        'strategy': 'Stratégie',
        'breach_against_restriction': 'Infraction à la restriction',
        'speak_with_earlylion': 'Parler avec Earlylion',
        'number': 'Numéro',
        'task': 'Tâche',
        'portfolio': 'Portefeuille',
        'cl_type': 'Type de client',
        'document_type': 'Type de document',
        'stocks': 'Actions',
        'balanced': 'Équilibré',
        'income': 'Revenu',
        'fixed_income': 'À revenu fixe',
        'growth': 'Croissance',
        'russian_bonds': 'Obligations russes',
        'us_stocks': 'Actions américaines',
        'asset_allocation': 'Allocation d’actifs',
        'concentration_risk': 'Risque de concentration',
        'client_meeting_preparation': 'Préparation de la réunion client',
        'send_information': 'Envoyer des informations',
        'follow_up_client': 'Relancer le client',
        'call_back': 'Rappel',
        'asset_allocation_breach': 'Violation de l’allocation d’actifs',
        'aml': 'LBC',
        'missing': 'Manquant',
        'expiration': 'Expiration',
        'form_a': 'Formulaire A',
        'contract': 'Contrat',
        'origin_of_wealth': 'Origine des fonds',
        'passport': 'Passeport',
        'document_xy': 'Document XY',
        "more_info_about": "Plus d'informations sur ...",
        "more_info_about_breaches_from": "Plus d'informations sur les infractions de ",
        "more_info_about_todos_from": "Plus d'informations sur les tâches à faire pour ",
        "more_info_about_missing_documents_from": "Plus d'informations sur les documents manquants de ",
        'prospect': 'Prospect',
        'tax_residency_declaration': 'Déclaration de résidence fiscale',
        'client_agreement': 'Contrat client',
        'proof_of_address': 'Justificatif de domicile',
      }
    },
    en: {
      translation: {
        "greeting": "Hi,",
        "performance": "Portfolio Performance",
        "regionAllocation": "Region Analysis",
        "assetClassAllocation": "Asset Class Analysis",
        "currencyAllocation": "Currency Analysis",
        'kpi': "KPI's",
        'asset_under_management_mgr': 'Managed Assets',
        'net_new_asset_mgr': 'Net New Assets',
        'income_information_mgr': 'Income Analysis',
        'new_clients_mgr': 'New Clients',
        'asset_under_management_adv': 'Managed Assets',
        'net_new_asset_adv': 'Net New Assets',
        'income_information_adv': 'Income Analysis',
        'new_clients_adv': 'New Clients',
        'client_contact': 'Client Contact',
        "openAll": "Open All",
        "closeAll": "Close All",
        "shortSummary": "Short",
        "longSummary": "Long",
        "loadingSummary": "Your summary is loading...",
        "loadingAudio": "Loading audio",
        "customer_note": "Customer Note",
        "date": "Date",
        "client": "Client",
        "type": "Type",
        "attendees": "Attendees",
        "comment": "Comment",
        "save": "Save",
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>How can I assist you today?</h3><p>Whether you need an overview of your portfolio's performance 📊, specific details on a particular client portfolio 🔍, or high-quality analyst insights on individual investment positions or sectors from <b><a href='https://rm.thescreener.com/home/login' target='_blank' class='hover-link'>theScreener</a></b> – I am here to help. Just let me know what information you need, and I will provide the necessary data accurately and effectively. 🚀</p>",
        'ask_question': 'Enter a question...',
        'reset-chat': 'Clear chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'My Calendar',
        'very-important-prompts': 'VIP',
        'load-response': 'Loading response...',
        'load-calendar': 'Loading calendar entries...',
        'description': 'Description',
        'no-events': 'No calendar entries available.',
        'prepare-me-for-meeting': 'Prepare me for my meeting.',
        'telephone': 'Telephone',
        'on_site': 'On site',
        'portfolio_performance': 'Portfolio Performance',
        'speak_with_consultant': 'Speak with Consultant',
        'help': 'Help',
        'copy_last_response': 'Copy Last Response',
        'export_chat_history_to_word': 'Export Chat History to Word',
        'minimize_chat': 'Minimize Chat',
        'maximize_chat': 'Maximize Chat',
        'close_chat': 'Close Chat',
        'speak_with_my_consultant': 'I want to speak with my consultant.',
        'breaches': 'Breaches',
        'to_do': 'To do',
        'missing_documents': 'Missing documents',
        'strategy': 'Strategy',
        'breach_against_restriction': 'Breach against restriction',
        'speak_with_earlylion': 'Speak with Earlylion',
        'number': 'Number',
        'task': 'Task',
        'portfolio': 'Portfolio',
        'cl_type': 'Client type',
        'document_type': 'Document type',
        'stocks': 'Stocks',
        'balanced': 'Balanced',
        'income': 'Income',
        'fixed_income': 'Fixed income',
        'growth': 'Growth',
        'russian_bonds': 'Russian bonds',
        'us_stocks': 'US stocks',
        'asset_allocation': 'Asset allocation',
        'concentration_risk': 'Concentration risk',
        'client_meeting_preparation': 'Client Meeting Preparation',
        'send_information': 'Send Information',
        'follow_up_client': 'Follow Up Client',
        'call_back': 'Call back',
        'asset_allocation_breach': 'Asset Allocation Breach',
        'aml': 'AML',
        'missing': 'Missing',
        'expiration': 'Expiration',
        'form_a': 'Form A',
        'contract': 'Contract',
        'origin_of_wealth': 'Origin of Wealth',
        'passport': 'Passport',
        'document_xy': 'Document XY',
        "more_info_about": "More info about ...",
        "more_info_about_breaches_from": "More info about the breaches from ",
        "more_info_about_todos_from": "More info about the 'To Do' for ",
        "more_info_about_missing_documents_from": "More info about the missing documents from ",
        'prospect': 'Prospect',
        'tax_residency_declaration': 'Tax Residency Declaration',
        'client_agreement': 'Client Agreement',
        'proof_of_address': 'Proof of Address',
      }
    },
    es: {
      translation: {
          "greeting": "¡Buen día,",
          "performance": "Rendimiento del Portafolio",
          "regionAllocation": "Análisis de Regiones",
          "assetClassAllocation": "Distribución de Clase de Activos",
          "currencyAllocation": "Distribución de Monedas",
          'kpi': "KPI's YTD",
          'asset_under_management_mgr': 'Activos Bajo Gestión',
          'net_new_asset_mgr': 'Nuevos Activos Netos',
          'income_information_mgr': 'Análisis de Ingresos',
          'new_clients_mgr': 'Nuevos Clientes en el Año',
          'asset_under_management_adv': 'Activos Bajo Gestión',
          'net_new_asset_adv': 'Nuevos Activos Netos',
          'income_information_adv': 'Análisis de Ingresos',
          'new_clients_adv': 'Nuevos Clientes en el Año',
          'client_contact': 'Contacto con el Cliente',
          "openAll": "Abrir todo",
          "closeAll": "Cerrar todo",
          "shortSummary": "Corto",
          "longSummary": "Largo",
          "loadingSummary": "Tu resumen se está cargando...",
          "loadingAudio": "Cargando audio",
          "customer_note": "Nota del Cliente",
          'date': 'Fecha',
          'client': 'Cliente',
          'type': 'Tipo',
          'attendees': 'Asistentes',
          'comment': 'Comentario',
          'save': 'Guardar',
          'chat': 'Assistant Lion',
          'chat_welcome_message': '<h3>¿Cómo puedo ayudarle hoy?</h3><p>Ya sea que necesite un resumen del rendimiento de sus carteras 📊, detalles específicos sobre un portfolio de cliente específico 🔍, o insights analíticos de calidad sobre posiciones de inversión individuales o sectores a través de <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> – estoy a su disposición. Simplemente indíqueme qué información requiere y proporcionaré los datos necesarios de manera precisa y efectiva. 🚀</p>',
          'ask_question': 'Ingresar pregunta...',
          'reset-chat': 'Eliminar chat',
          'chat-tab': 'Chat',
          'calendar-tab': 'Mi Calendario',
          'very-important-prompts': 'VIP',
          'load-response': 'Cargando respuesta...',
          'load-calendar': 'Cargando entradas del calendario...',
          'description': 'Descripción',
          'no-events': 'No hay entradas de calendario disponibles.',
          'prepare-me-for-meeting': 'Prepárame para mi reunión.',
          'telephone': 'Teléfono',
          'on_site': 'En el sitio',
          'portfolio_performance': 'Rendimiento del Portafolio',
          'speak_with_consultant': 'Hablar con un Asesor',
          'help': 'Ayuda',
          'copy_last_response': 'Copiar Última Respuesta',
          'export_chat_history_to_word': 'Exportar Historial del Chat a Word',
          'minimize_chat': 'Minimizar Chat',
          'maximize_chat': 'Maximizar Chat',
          'close_chat': 'Cerrar Chat',
          'speak_with_my_consultant': 'Quiero hablar con mi asesor.',
          'breaches': 'Infracciones',
          'to_do': 'Por hacer',
          'missing_documents': 'Documentos faltantes',
          'strategy': 'Estrategia',
          'breach_against_restriction': 'Infracción contra restricción',
          'speak_with_earlylion': 'Hablar con Earlylion',
          'number': 'Número',
          'task': 'Tarea',
          'portfolio': 'Portafolio',
          'cl_type': 'Tipo de cliente',
          'document_type': 'Tipo de documento',
          'stocks': 'Acciones',
          'balanced': 'Equilibrado',
          'income': 'Ingresos',
          'fixed_income': 'Renta fija',
          'growth': 'Crecimiento',
          'russian_bonds': 'Bonos rusos',
          'us_stocks': 'Acciones estadounidenses',
          'asset_allocation': 'Asignación de activos',
          'concentration_risk': 'Riesgo de concentración',
          'client_meeting_preparation': 'Preparación de reunión con cliente',
          'send_information': 'Enviar información',
          'follow_up_client': 'Seguimiento al cliente',
          'call_back': 'Devolver la llamada',
          'asset_allocation_breach': 'Incumplimiento de asignación de activos',
          'aml': 'Prevención de lavado de dinero',
          'missing': 'Faltante',
          'expiration': 'Vencimiento',
          'form_a': 'Formulario A',
          'contract': 'Contrato',
          'origin_of_wealth': 'Origen del patrimonio',
          'passport': 'Pasaporte',
          'document_xy': 'Documento XY',
          "more_info_about": "Más información sobre ...",
          "more_info_about_breaches_from": "Más información sobre las infracciones de ",
          "more_info_about_todos_from": "Más información sobre las tareas pendientes de ",
          "more_info_about_missing_documents_from": "Más información sobre los documentos faltantes de ",
          'prospect': 'Prospecto',
          'tax_residency_declaration': 'Declaración de residencia fiscal',
          'client_agreement': 'Acuerdo con el cliente',
          'proof_of_address': 'Comprobante de domicilio',
      },
    },
    it: {
      translation: {
        "greeting": "Buongiorno,",
        "performance": "Performance del Portfolio",
        "regionAllocation": "Analisi Regionale",
        "assetClassAllocation": "Distribuzione per Classe di Asset",
        "currencyAllocation": "Composizione Valutaria",
        'kpi': "KPI's YTD",
        'asset_under_management_mgr': 'Patrimonio Gestito',
        'net_new_asset_mgr': 'Nuovi Patrimoni Netto',
        'income_information_mgr': 'Analisi dei Redditi',
        'new_clients_mgr': "Nuovi Clienti nell'Anno",
        'asset_under_management_adv': 'Patrimonio Gestito',
        'net_new_asset_adv': 'Nuovi Patrimoni Netto',
        'income_information_adv': 'Analisi dei Redditi',
        'new_clients_adv': "Nuovi Clienti nell'Anno",
        'client_contact': 'Contatto Cliente',
        "openAll": "Apri Tutto",
        "closeAll": "Chiudi Tutto",
        "shortSummary": "Breve",
        "longSummary": "Lungo",
        "loadingSummary": "Caricamento del tuo riassunto in corso...",
        "loadingAudio": "Caricamento audio",
        "customer_note": "Nota del Cliente",
        'date': 'Data',
        'client': 'Cliente',
        'type': 'Tipo',
        'attendees': 'Partecipanti',
        'comment': 'Commento',
        'save': 'Salva',
        'chat': 'Assistant Lion',
        'chat_welcome_message': '<h3>Come posso aiutarti oggi?</h3><p>Se hai bisogno di una panoramica sulla performance dei tuoi portafogli 📊, dettagli specifici su un particolare portafoglio cliente 🔍 o informazioni di analisi di qualità su singole posizioni di investimento o settori da <b><a href="https://rm.thescreener.com/home/login" target="_blank" class="hover-link">theScreener</a></b> – sono a tua disposizione. Dicci semplicemente quali informazioni ti servono e fornirò i dati necessari in modo preciso ed efficace. 🚀</p>',
        'ask_question': 'Inserisci una domanda...',
        'reset-chat': 'Cancella chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'Il mio calendario',
        'very-important-prompts': 'VIP',
        'load-response': 'Carico risposta...',
        'load-calendar': 'Caricamento appuntamenti del calendario...',
        'description': 'Descrizione',
        'no-events': 'Nessun evento in calendario.',
        'prepare-me-for-meeting': 'Preparami per il mio appuntamento.',
        'telephone': 'Telefono',
        'on_site': 'Sul posto',
        'portfolio_performance': 'Rendimento del Portafoglio',
        'speak_with_consultant': 'Parla con un Consulente',
        'help': 'Aiuto',
        'copy_last_response': "Copia l'Ultima Risposta",
        'export_chat_history_to_word': 'Esporta la Cronologia della Chat in Word',
        'minimize_chat': 'Riduci Chat',
        'maximize_chat': 'Ingrandisci Chat',
        'close_chat': 'Chiudi Chat',
        'speak_with_my_consultant': 'Voglio parlare con il mio consulente.',
        'breaches': 'Violazioni',
        'to_do': 'Da fare',
        'missing_documents': 'Documenti mancanti',
        'strategy': 'Strategia',
        'breach_against_restriction': 'Violazione della restrizione',
        'speak_with_earlylion': 'Parlare con Earlylion',
        'number': 'Numero',
        'task': 'Compito',
        'portfolio': 'Portafoglio',
        'cl_type': 'Tipo di cliente',
        'document_type': 'Tipo di documento',
        'stocks': 'Azioni',
        'balanced': 'Bilanciato',
        'income': 'Reddito',
        'fixed_income': 'Reddito fisso',
        'growth': 'Crescita',
        'russian_bonds': 'Obbligazioni russe',
        'us_stocks': 'Azioni statunitensi',
        'asset_allocation': 'Allocazione degli attivi',
        'concentration_risk': 'Rischio di concentrazione',
        'client_meeting_preparation': 'Preparazione incontro cliente',
        'send_information': 'Inviare informazioni',
        'follow_up_client': 'Seguire il cliente',
        'call_back': 'Richiamare',
        'asset_allocation_breach': 'Violazione allocazione attività',
        'aml': 'Antiriciclaggio',
        'missing': 'Mancante',
        'expiration': 'Scadenza',
        'form_a': 'Modulo A',
        'contract': 'Contratto',
        'origin_of_wealth': 'Origine del patrimonio',
        'passport': 'Passaporto',
        'document_xy': 'Documento XY',
        "more_info_about": "Ulteriori informazioni su ...",
        "more_info_about_breaches_from": "Ulteriori informazioni sulle violazioni di ",
        "more_info_about_todos_from": "Ulteriori informazioni sulle attività da fare per ",
        "more_info_about_missing_documents_from": "Ulteriori informazioni sui documenti mancanti di ",
        'prospect': 'Prospetto',
        'tax_residency_declaration': 'Dichiarazione di residenza fiscale',
        'client_agreement': 'Accordo con il cliente',
        'proof_of_address': 'Prova di residenza',
      }
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
